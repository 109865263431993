<template>
  <div v-if="!isReadonly">
    <p>{{ $t('question_requires_to_put_the_answer_options_in_the_correct_order.one') }}.</p>

    <Draggable
      :list="answersList"
      :animation="200"
      @change="onChange"
    >
      <TransitionGroup
        tag="ul"
        type="transition"
        class="task__answers"
      >
        <TrainingQuizAnswer
          v-for="(answer, idx) in answersList"
          :key="answer.id"
          :answer="answer"
          :index="idx"
          :is-revealed="isRevealed"
          :is-readonly="isReadonly"
          is-ranking-type
          tag="li"
        />
      </TransitionGroup>
    </Draggable>
  </div>

  <ul
    v-else
    class="task__answers"
  >
    <TrainingQuizAnswer
      v-for="(answer, idx) in answersList"
      :key="answer.id"
      :answer="answer"
      :index="idx"
      :is-revealed="isRevealed"
      :is-readonly="isReadonly"
      :is-show-correct-answer="isShowCorrectAnswer"
      is-ranking-type
      tag="li"
    >
      <span class="text--primary">{{ idx + 1 }})</span>

      <p class="flex-grow-1 mb-0">
        {{ answer.answer }}
      </p>

      <VChip
        v-if="isShowCorrectAnswer"
        :color="answer.settings.isCorrect ? 'success' : 'error'"
        label
        small
      >
        {{ answer.settings.key }}
      </VChip>
    </TrainingQuizAnswer>
  </ul>
</template>

<script>
import Draggable from 'vuedraggable'

import TrainingQuizAnswer from '@components/TrainingQuizAnswer.vue'

export default {
  name: 'TrainingQuizQuestionTypeRanking',

  components: {
    Draggable,
    TrainingQuizAnswer
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },

    isRevealed: {
      type: Boolean,
      default: false
    },

    isReadonly: {
      type: Boolean,
      default: false
    },

    isShowCorrectAnswer: {
      type: Boolean,
      default: false
    },

    isMultipleChoice: {
      type: Boolean,
      default: false
    },

    answers: {
      type: Array,
      default: () => []
    },

    availableAnswers: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      answersList: []
    }
  },

  computed: {
    userAnswer () {
      return this.answersList.map(answer => answer.id)
    }
  },

  created () {
    this.register()
  },

  methods: {
    onChange () {
      this.$emit('input', this.userAnswer)
    },

    register () {
      this.answersList = [...this.answers]
    },
  }
}
</script>
