import { mapGetters } from 'vuex'

import { skipStep } from '@/api/api'
import { UDate } from '@/utils/date'
import { formatInt, selectUnit } from '@/utils/common'
import TrainingTimer from '@components/TrainingTimer.vue'
import * as getters from '@/store/getters/types'

class Status {
  constructor (title = '', color = '') {
    this.title = title
    this.color = color
  }
}

export default {
  components: { TrainingTimer },

  data () {
    return {
      isSkipping: false
    }
  },

  computed: {
    ...mapGetters({
      session: getters.SESSION,
      step: getters.STEP,
      now: getters.NOW
    }),

    summativeStartsAt () {
      return new UDate(this.session?.settings?.summative_starts_at).valueOf()
    },

    summativeEndsAt () {
      return new UDate(this.session?.settings?.summative_ends_at).valueOf()
    },

    isAvailableSummativeRaw () {
      const summativeStartsAt = new UDate(this.session?.settings?.summative_starts_at).valueOf()
      const summativeEndsAt = new UDate(this.session?.settings?.summative_ends_at).valueOf()
      let str = ''

      if (!summativeStartsAt && !summativeEndsAt) { return '' }

      if (summativeStartsAt && (summativeStartsAt > Date.parse(this.now))) {
        // Итоговый (оценочный) урок можно будет пройти…
        str += this.$t('step_will_be_available_at.one')
      } else if (summativeEndsAt && (summativeEndsAt < Date.parse(this.now))) {
        // Итоговый (оценочный) урок можно было пройти…
        str += this.$t('step_was_available_at.one')
      } else {
        // Итоговый (оценочный) урок можно пройти…
        str += this.$t('step_is_available_at.one')
      }

      // …С <date> по <date>
      if (summativeStartsAt) {
        str += ` ${this.$t('from.one').toLowerCase()} ${UDate.formatDateTime(summativeStartsAt)}`
      }

      if (summativeEndsAt) {
        str += ` ${this.$t('to.one').toLowerCase()} ${UDate.formatDateTime(summativeEndsAt - UDate.minuteInSeconds)}`
      }

      return str
    },

    timerOn () {
      const { code: type } = this.step.lesson_type_info

      if (type && this[type]) {
        return this[type].is_started && !this[type].is_completed
      }

      return false
    },

    maxSecondsPerAttempt () {
      const { code: type } = this.step.lesson_type_info

      if (type && this[type]) {
        return UDate.minuteInSeconds * this[type].settings.max_minutes_per_attempt
      }

      return 0
    },

    leftTime () {
      const { code: type } = this.step.lesson_type_info

      if (type && this[type]) {
        let pastTime = 0

        if (this[type].is_started && !this[type].is_completed) {
          pastTime = Math.max(0, Math.ceil(this.now - new Date(this[type].started_at)) / UDate.secondInMilliseconds)
        }

        return Math.max(0, Math.floor(this.maxSecondsPerAttempt - pastTime))
      }

      return 0
    },

    spentTime () {
      const { code: type } = this.step.lesson_type_info

      if (type && this[type] && this[type].is_completed) {
        const maxMinutesPerAttempt = this[type].settings.max_minutes_per_attempt
        const startedAt = new Date(this[type].started_at)
        const completedAt = new Date(this[type].completed_at)

        let seconds = Math.max(0, Math.ceil((completedAt - startedAt) / UDate.secondInMilliseconds))
        if (maxMinutesPerAttempt) {
          seconds = Math.min(seconds, UDate.minuteInSeconds * maxMinutesPerAttempt)
        }

        return UDate.formatTime(seconds)
      }

      return ''
    }
  },

  methods: {
    selectUnit,
    formatInt,
    formatDate: UDate.formatDate,
    formatDateTime: UDate.formatDateTime,

    // пропустить шаг
    async skip () {
      const data = new FormData()
      data.append('step', this.step.id)

      this.isSkipping = true
      const response = await skipStep(this.session.id, this.step.id, data)

      // Если id шага неопределен или нулевой вернемся на страницу списка шагов
      let path = `/sessions/${response.session.id}`
      if (this.step.id) {
        path = `${path}/steps/${response.step.id}`
      }

      this.$router.push(path).catch(() => {})
      this.isSkipping = false
    },

    getStatus (attempt) {
      if (attempt) {
        switch (true) {
          case attempt.is_accepted:
            return new Status('step_status_accepted.one', 'success')
          case attempt.is_evaluated:
            return new Status('step_status_evaluated.one', 'error')
          case attempt.is_completed:
            return new Status('step_status_completed.one', 'primary')
          case this.quiz.is_skipped:
            return new Status('step_status_skipped.one', 'warning')
          case attempt.is_started:
            return new Status('step_status_started.one', 'info')
          default:
            return new Status()
        }
      }

      return new Status()
    }
  }
}
